*{
    margin: 0;
    padding: 0;
    outline:none;
    text-decoration: none;
    list-style-position: inside;
    box-sizing:border-box;
    -webkit-overflow-scrolling: touch;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}
input[type='text'], input[type='email'], input[type='tel'], input[type='password'], textarea{-webkit-appearance: none;}
input[type='submit'], button{cursor: pointer; -webkit-appearance: none;}
a{text-decoration: none !important; outline: none !important; color: #ed3237;}
a:hover{color: #970000;}
a img{border: none;}
.clr{clear: both;}
.fleft{float: left;}
.fright{float: right;}
.col-2{width: 50%; float: left}
.col-3{width: 33.33%; float: left}
.col-4{width: 25%; float: left}
.view-menu, .view-sub-menu, .view-search{display: none;}
.img404{width: 100%;}
::-webkit-input-placeholder { color:#555 !important; }
::-moz-placeholder { color:#555 !important; }
:-ms-input-placeholder { color:#555 !important;}
input:-moz-placeholder { color:#555 !important; }
.button{
    transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
    -moz-transition: 0.5s ease;
    -ms-transition: 0.5s ease;
    -o-transition: 0.5s ease;
    cursor: pointer;
    background: #3a3939;
    color: #fff;
}
.button:hover{
    background: #ff9600;
}
.zing-select select::-ms-expand {display: none;}
.zing-select select{
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	padding-right: 20px !important;
}
.zing-select{
	position: relative;
}
.zing-select::after{
	content: "\f0d7";
	font-family: FontAwesome;
	color: #999;
	position: absolute;
	top: 50%;
	right: 10px;
	font-size: 18px;
	line-height: 0;
}
.zing-date{
    position: relative;
}
.zing-date::after{
    content: "\f073";
    font-family: FontAwesome;
    color: #999;
    position: absolute;
    top: 50%;
    right: 10px;
    font-size: 14px;
    line-height: 0;
}
.zing-content .wp-caption{
    width: 100% !important;
    text-align: center;
}
.zing-content img{
    max-width: 100%;
    width: auto;
    height: auto;
}
.zing-content img.full-width{
    max-height: inherit;
}
.zing-content table{
    width: 100%;
    margin: 0 0 15px 0;
}
.zing-content td{
    border: solid 1px #ccc;
    padding: 5px;
}
.zing-required{
    color: red;
    font-weight: bold;
}
.mark{
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    right:0;
    bottom: 0;
    display: inline-block;
    transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
    -moz-transition: 0.5s ease;
    -ms-transition: 0.5s ease;
    -o-transition: 0.5s ease;
    opacity:0;
    visibility: hidden;
}
.slide-bg{
    background: transparent;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 9
}
.contact-success {
    margin: 10px 0px;
    width: 100%;
    color: green;
    font-weight: bold;
}
.contact-error {
  margin: 10px 0px;
  width: 100%;
  color: red;
  font-weight: bold;
}
body{
    font-family: Arial, Helvetica, sans-serif;
    font-size:13px;
    color:#4d4d4d;
    background: #f0f0f0;
}
body p{
    line-height: 1.5;
    margin-bottom: 15px;
}
/* breadcrumb */
.breadcrumb-box{
    width:100%;
    float: left;
}
.breadcrumb{
    padding: 17px 15px;
    margin: 0 0 0 15px;
}
.breadcrumb-box li{
    float:left;
}
.breadcrumb-box ul{
    float: left;
}
.breadcrumb-box li a{
    font-weight: bold;
    text-decoration: none;
    font-size: 16px;
    color: #008080;
}
.breadcrumb-box li a:after {
    content: " ";
    width: 7px;
    display: inline-block;
    height: 7px;
    background: url(../images/next-bcrum.png) no-repeat 0px 0px;
    margin: 0px 7px;
}
.breadcrumb-box li:last-child a:after {
    display:none;
}
.breadcrumb-box li span{
    color:#0574bb;
    font-size:13px;
}
.breadcrumb-box li a span{
    color:#767676;
}
.breadcrumb>li+li:before{
    display: none;
}
/* end breadcrumb */
/* pagination */
.pagination{
    width:100%;
    margin-bottom:10px;
    margin-top: 0;
    text-align: right;
}
.pagination a{
    margin-left:10px;
}
.pagination a, .pagination span{
    display: inline-block;
    font-weight:bold;
    min-width:10px;
    height:27px;
    padding:0px 10px;
    line-height:30px;
    background:#00a859;
    font-size:13px;
    color:#fff;
    text-align:center;
    cursor:pointer;
}
.pagination a:hover{
    color:#FFF;
    background:#ed3237;
}
.pagination span{
    color:#FFF;
    background:#ed3237;
    margin-left:10px;
}
/* end pagination */
/* search suggets */
.suggest-box {
    float: left;
    clear: both;
    position: absolute;
    z-index: 9999;
    background-color: #eee;
    right: 0;
    top: 28px;
    display: none;
}
.suggest-item {
    width: 350px;
    float: left;
    clear: both;
    border-bottom: dashed 1px #ccc;
    padding: 10px;
    box-sizing: border-box;
}
.suggest-item img {
    float: left;
    margin-right: 10px;
    border: solid 1px #ccc;
    padding: 5px;
    background-color: #fff;
}
.suggest-item h2 {
    font-size: 12px;
    font-weight: bold;
    font-family: arial;
    text-decoration: none;
    margin: 0px;
    line-height: 1.5;
}
.suggest-item h2 a {
    text-decoration: none;
    color: #0574BB;
}
.suggest-price {
    font-weight: bold;
    color: red;
    margin-top: 5px;
    font-size: 14px;
    display: block;
}
.search-empty{
    text-align: center;
    padding: 20px;
    font-size: 18px;
    color: #555;
}
/* search suggets */
/* contact-from */
.form-group{
    margin-bottom: 15px;
}

.form-group label{
    float: left;
} 
.form-left {
  width: 50%;
  float: left;
  padding-right: 10px;
}
.form-right {
  width: 50%;
  float: right;
  padding-left: 10px;
}
.contact-form .group-3{
    margin: 0 -15px;
}
.contact-form .group-3 .col-3{
    padding: 0 15px;
}
.contact-form .form-control{
    border-radius: 0;
    height: 31px;
    border: 1px solid #ddd;
}
.contact-form textarea.form-control{
    height: 140px;
}
.contact-form label{
    font-weight: bold;
}
.contact-form ::-webkit-input-placeholder { color:#2a2b2c !important; font-size: 13px;}
.contact-form ::-moz-placeholder { color:#2a2b2c !important; font-size: 13px;}
.contact-form :-ms-input-placeholder { color:#2a2b2c !important; font-size: 13px;}
.contact-form input:-moz-placeholder { color:#2a2b2c !important; font-size: 13px;}
.form-message{
    padding-right: 120px;
    position: relative;
}
.send-message{
    width: 100px;
    height: 30px;
    line-height: 32px;
    background: #d72523;
    color: #fff;
    border: none;
    outline: none;
    transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
    -moz-transition: 0.5s ease;
    -ms-transition: 0.5s ease;
    -o-transition: 0.5s ease;
}
.send-message:hover{
    background: #a20200;
}
.btn-reset{
    width: 100px;
    height: 30px;
    line-height: 32px;
    background: #c1c1c1;
    color: #fff;
    border: none;
    outline: none;
    margin-left: 5px;
    transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
    -moz-transition: 0.5s ease;
    -ms-transition: 0.5s ease;
    -o-transition: 0.5s ease;
}
.btn-reset:hover{
    background: #555;
}
.contact-map iframe{
    width: 100%;
    min-height: 250px;
    max-height: 480px;
}
.contact-map p{
    margin: 0;
    line-height: 0;
}
/* end contact-from */
/* post-list */
.post-item{
    position: relative;
    padding-left: 255px;
    min-height: 175px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e6e6e6;
}
.post-item img{
    position: absolute;
    top: 0;
    left: 0;
    width: 235px;
    height: 165px;
}
.post-title{
    font-size: 15px;
    margin:0 0 10px 0;
    color: #017035;
    text-transform: uppercase;
}
.post-title a {
    color: #424343;
}
.post-title a:hover{
    color: #ed3237;
}
.post-excerpt{
    color: #717171;
    font-size: 13px;
    margin: 0;
}
.post-date{
    font-size: 12px;
    color: #858585;
    margin: 20px 0 0 0;
}
.widget-popular ul{
    margin: 0;
    list-style: none;
}
.widget-popular li{
    position: relative;
    padding-left: 120px;
    min-height: 85px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #eee;
}
.widget-popular li img{
    position: absolute;
    top: 0;
    left: 0;
}
.widget-popular li a{
    color: #424343;
    text-transform: uppercase;
}
.widget-popular li a:hover{
    color: #ed3237;
}
.widget-popular .post-date{
    margin: 5px 0 0 0;
}
/* end post-list */
#header{
    border-top: 5px solid #cd0006;
    background: url(../images/bg-header.png) repeat-x left bottom #fff;
}
.header-left{
    width: 18%;
}
.header-right{
    width: 82%;
}
.logo{
    margin: 20px 0;
}
.logo img{
    max-width: 100%;
    width: auto;
    height: auto;
}
.danh-muc-box{
    height: 400px;
    width: 300px;
    background: #FFFFFF;
    float: left;
    position: relative;
}
.danh-muc-btn{
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
    background: url(../images/icon-danh-muc.png) no-repeat left 12px center #00a859;
    padding: 9px 20px 8px 38px;
    cursor: pointer;
    border-bottom: 3px solid #008546;
}
#header .danh-muc-menu{
    position: absolute;
    top: 41px;
    left: 0;
    width: 100%;
    z-index: 200;
    display: none;
    box-shadow: 0 0 1px #bbb;
  }
  .danh-muc-menu{
    position: relative;
    padding: 10px 0;
  }
  .danh-muc-menu ul{
    list-style: none;
  }
  
  .danh-muc-menu > ul > li{
    padding: 0 5px;
    margin-bottom: 10px;
  }
  
  .danh-muc-menu > ul > li > a {
    display: block;
    padding: 6px 0 0px 0;
    color: #606060;
    font-size: 16px;
    font-family: inherit;
    font-weight: bold;
    background: #f7f7f7;
    border-radius: 20px;
  }
  
  .danh-muc-menu > ul > li > a:hover{
    background: #ed3237;
    color: #fff;
  }
.header-right-top{
    background: #ed3237;
    position: relative;
    height: 36px;
    padding: 0 40px 0 40px;
}
.header-right-top::before{
    content: "";
    width: 50px;
    height: 36px;
    background: url(../images/header-right-top1.png);
    position: absolute;
    left: 0;
    top: 0;
}
.header-right-top::after{
    content: "";
    width: 50px;
    height: 36px;
    background: url(../images/header-right-top2.png);
    position: absolute;
    right: 0;
    top: 0;
}
.box-ho-tro{
    margin: 4px 0 0 0;
    position: relative;
    z-index: 20;
}
.btn-ho-tro{
    /* background: url(../images/icon-ho-tro.png) no-repeat left 6px center #fff; */
    color: #666666;
    width: 175px;
    padding: 7px 10px 4px 33px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
}
.box-hotro-top{
    position: absolute;
    top: 32px;
    right: 0;
    width: 380px;
    border: 1px solid #d8d8d8;
    border-radius: 10px;
    z-index: 100;
    background: #fff;
    padding: 0 15px;
    display: none;
}
.item-hotro{
    border-top: 1px solid #d8d8d8;
    padding: 12px 0;
}
.item-hotro:first-child{
    border-top: 0;
}
.box-hotro-top p{
    margin: 0;
    float: left;
    margin-right: 15px;
}
.box-hotro-top a{
    float: left;
    margin-right: 15px;
}
.main-menu{
    position: relative;
    z-index: 20;
}
.main-menu ul{
    margin: 0;
    list-style: none;
}
.main-menu ul li{
    float: left;
    margin-right: 3px;
}
.main-menu ul li a{
    padding: 8px 10px;
    color: #fff;
    display: block;
    font-size: 14px;
}
.main-menu ul li.active a, .main-menu ul li a:hover{
    color: #F9E79F;
}
.header-right-center{
    padding: 25px 0 25px 45px;
}
.search-box{
    position: relative;
}
.search-form{
    width: 360px;
    height: 28px;
    border: 1px solid #bababa;
    border-radius: 20px;
    background: #fff;
}
.search-form select{
    width: 65px;
    margin: 5px 0 0 10px;
    float: left;
    border: none;
    background: none;
    font-size: 13px;
    color: #000;
}
.txt-search{
    width: 250px;
    height: 28px;
    line-height: 28px;
    border: none;
    outline: none;
    font-size: 13px;
    padding: 0 10px;
    background: none;
    color: #000;
}
.btn-search{
    color: #888;
    float: right;
    font-size: 18px;
    border: none;
    outline: none;
    background: none;
    padding-right: 10px;
}
.search-form ::-webkit-input-placeholder { color:#bababa !important; font-size: 13px;}
.search-form ::-moz-placeholder { color:#bababa !important; font-size: 13px;}
.search-form :-ms-input-placeholder { color:#bababa !important; font-size: 13px;}
.search-form input:-moz-placeholder { color:#bababa !important; font-size: 13px;}
.social{
    margin: 0 0 0 20px;
}
.social a{
    width: 28px;
    height: 28px;
    display: inline-block;
    background: #1c891f;
    color: #fff !important;
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
    margin-right: 5px;
    font-size: 15px;
    transition: 0.3s ease;
    -webkit-transition: 0.3s ease;
    -moz-transition: 0.3s ease;
    -ms-transition: 0.3s ease;
    -o-transition: 0.3s ease;
}
.social .social-facebook:hover{
    background: #00267e;
}
.social .social-twitter:hover{
    background: #00608d;
}
.social .social-youtube:hover{
    background: #990004;
}
.social .social-google:hover{
    background: #175c00;
}
.cart-mini{
    margin-left: 20px;
}
.cart-mini a{
    display: inline-block;
    background: url(../images/icon-cart.png) no-repeat left 10px center #fff;
    color: #6c6c6c;
    text-transform: uppercase;
    padding: 6px 15px 3px 45px;
    border-radius: 20px;
    border: 1px solid #bababa;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
}
.account-box{
    border: 1px solid #bababa;
    border-radius: 20px;
    background: url(../images/icon-account.png) no-repeat left 8px center #fff;
    padding: 5px 10px 3px 30px;
    margin-left: 15px;
}
.account-box ul{
    margin: 0;
    list-style: none;
}
.account-box li{
    float: left;
}
.account-box li a{
    font-size: 12px;
    color: #767676;
    font-weight: bold;
    padding: 0 8px;
    text-transform: uppercase;
}
.account-box li a:hover{
    color: #141414;
}
.header-right-bottom{
    margin-top: 20px;
    padding-left: 70px;
    background: url(../images/icon-phone.png) no-repeat left 45px center;
}
.header-right-bottom p{
    float: left;
    font-weight: bold;
    font-size: 14px;
    color: #767676;
    margin: 0 0 0 40px;
    position: relative;
}
.header-right-bottom p::after{
    content: "";
    width: 2px;
    height: 12px;
    background: #828282;
    position: absolute;
    top: 3px;
    left: -20px;
}
.header-right-bottom p:first-child{
    margin-left: 0;
}
.header-right-bottom p:first-child::after{
    display: none;
}
.header-right-bottom p span{
    color: #cd0006;
}
#footer{
    background: #fff;
    margin-top: 10px;
    font-size: 16px;
    color: #000;
    font-style: italic;
    padding-bottom: 10px;
    border-top: 2px solid #cd0006;
    border-bottom: 3px solid #D3D3D3;
}

.footer-row{
    margin: 0 -15px;
}
.footer-widget{
    width: 20%;
    float: left;
    padding: 0 15px;
  }
  .footer-widget:nth-child(1){
    width: 40%;
  }
  
  .footer-widget:nth-child(2){
    width: 40%;
  }
  
  .footer-widget .widget-title{
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    color: #ed3237;
    border-bottom: 1px solid #ddd;
    margin: 0;
    padding: 19px 0 15px 0;
    margin-bottom: 10px;
    font-style: normal;
    font-style: initial;
  }
  .footer-widget p{
    margin-bottom: 5px;
  }
  .footer-widget ul{
    margin: 0;
  }
  .footer-widget li{
    margin-bottom: 6px;
  }
  .footer-widget li a{
    color: #000;
  }
  .footer-widget li a:hover{
    color: #ed3237;
  }
.copyright{
    border-top: 1px solid #ddd;
    padding: 11px 0;
    text-align: center;
    margin-top: 12px;
}
.bottom-list{
    background: #fff;
    display: flex;
    flex-flow: row column;
}
.bottom-widget{
    padding: 15px 10px;
    float: left;
}
.bottom-widget .widget-title{
    font-size: 15px;
    font-weight: bold;
    color: #ff0000;
    text-transform: uppercase;
    margin: 0 0 15px 0;
}
.widget-bao-hanh{
    font-size: 12px;
    width: 23%;
}
.widget-bao-hanh .gio-mo-cua{
    border-radius: 20px;
    background: #f0f0f0;
    padding: 5px 15px;
    margin-bottom: 5px;
    color: #000;
    text-transform: uppercase;
}
.widget-bao-hanh .gio-mo-cua span{
    margin-right: 30px;
    min-width: 45px;
    display: inline-block;
}
.widget-bao-hanh .gio-mo-cua font{
    color: #ff0000;
    font-size: 14px;
}
.widget-bao-hanh p{
    margin-bottom: 10px;
    line-height: 1.3;
}
.widget-tin-tuc{
    width: 41%;
    float: left;
    border-left: 1px solid #dedede;
    border-right: 1px solid #dedede;
    padding: 15px;
}
.widget-tin-tuc ul{
    margin: 0 -25px;
    list-style: none;
}
.widget-tin-tuc li{
    padding: 0 25px;
}
.widget-tin-tuc img{
    float: left;
    margin-right: 20px;
    border: 3px solid #e9e9e9;
}
.tin-tuc-first{
    margin-bottom: 20px;
    min-height: 110px;
}
.tin-tuc-first img{
    width: 170px;
    height: 110px;
}
.tin-tuc-first-tilte{
    margin: 0;
    font-size: 15px;
}
.tin-tuc-first-tilte a{
    color: #313131;
}
.tin-tuc-first-tilte a:hover{
    color: #ef1e23;
}
.tin-tuc-excerpt{
    color: #787878;
    font-size: 14px;
    margin: 10px 0 0 0;
    font-weight: lighter;
}
.tin-tuc-tilte{
    font-size: 14px;
    margin: 8px 0 0 0;
}
.tin-tuc-tilte a{
    color: #4d4d4d;
    background: url(../images/list1.png) no-repeat left top 6px;
    padding-left: 10px;
}
.tin-tuc-tilte a:hover{
    color: #ef1e23;
}
.widget-fanppage{
    width: 36%;
}
.slider-doi-tac{
    background: #fff;
    margin: 10px 0;
    padding: 0 30px;
}
.slider-doi-tac-item{
    height: 70px;
}
.slider-doi-tac-item img{
    position: absolute;
    max-width: 80%;
    max-height: 80%;
    width: inherit !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
.home-top{
    margin-bottom: 20px;
}
.home-left{
    width: 20%;
    float: left;
}
.home-right{
    width: 80%;
    float: left;
}
.slideshow .item img{
    display: block;
    width: 100%;
    height: auto;
    max-height: 370px;
    min-height: 180px;
}
.slideshow a{
    position: relative;
    z-index: 10;
    display: block;
}
.slideshow .owl-dots{
    position: absolute;
    bottom: 5px;
    width: 100%;
}
.slideshow .owl-theme .owl-dots .owl-dot span{
    width: 12px;
    height: 12px;
    margin: 0 5px;
    background: #fff;
}
.slideshow .owl-theme .owl-dots .owl-dot.active span, .slideshow .owl-theme .owl-dots .owl-dot:hover span{
    background: #ed3237;
}
.owl-theme .owl-controls{
    margin: 0;
}
.content-box{
    background:#fff;
    padding: 25px 10px 20px 10px;
}
.home-box{
    padding-bottom: 0;
    margin-top: 10px;
}
.box-title{
    color: #5d5d5d;
    font-size: 17px;
    text-transform: uppercase;
    border-bottom: 1px solid #ccc;
    font-weight: bold;
    margin: 0 0 15px 0;
    padding: 0 0 15px 0;
    position: relative;
}
.box-title::after{
    content: "";
    width: 220px;
    height: 3px;
    position: absolute;
    bottom: -2px;
    left: 0;
    background: #ed3237;
}
.page-title{
    font-size: 13px;
    text-transform: uppercase;
    color: #313131;
    margin: 0 0 15px 0;
    font-weight: bold;
}
.share-box{float:left;display:table;padding-top:0px;}
.textshare{float:left;font-weight:bold; margin: 12px 0 0 15px; text-transform: uppercase; color: #363636; font-size: 12px;}
.textshare p{margin: 0;}
.share-box a{display:block;line-height:35px;height:35px;}
.share-box div{float:left;margin:0px 3px;}
.share-box div:hover{-webkit-transition:all 0.3s ease 0s;transition:all 0.3s ease 0s;}
.share-box .sharefacebook{margin-top:10px;}
.share-box .facebook:hover{background-position:-1px -40px;}
/* .share-box .facebook{background-image:url(../images/mangxahoi.png);width:32px;height:32px;background-position:-1px -2px;cursor:pointer;margin-top:5px;} */
/* .share-box .google{background-image:url(../images/mangxahoi.png);width:32px;height:32px;background-position:-37px -2px;cursor:pointer;margin-top:5px;} */
/* .share-box .google:hover{background-position:-37px -40px;} */
/* .share-box .tweet{background-image:url(../images/mangxahoi.png);width:32px;height:32px;background-position:-73px -2px;cursor:pointer;margin-top:5px;} */
/* .share-box .tweet:hover{background-position:-73px -40px;} */
/* .share-box .sharethis{background-image:url(../images/mangxahoi.png);width:32px;height:32px;background-position:-109px -2px;cursor:pointer;margin-top:5px;} */
/* .share-box .sharethis:hover{background-position:-109px -40px;} */
/* .share-box .digg{background-image:url(../images/mangxahoi.png);width:32px;height:32px;background-position:-145px -2px;cursor:pointer;margin-top:5px;} */
/* .share-box .digg:hover{background-position:-145px -40px;} */
/* .share-box .flickr{background-image:url(../images/mangxahoi.png);width:32px;height:32px;background-position:-181px -2px;cursor:pointer;margin-top:5px;} */
/* .share-box .flickr:hover{background-position:-181px -40px;} */
/* .share-box .skype{background-image:url(../images/mangxahoi.png);width:32px;height:32px;background-position:-217px -2px;cursor:pointer;margin-top:5px;} */
/* .share-box .skype:hover{background-position:-217px -40px;} */
/* .share-box .google-button, .share-box .fb-like{float: left;margin:13px 10px 0 0;} */
.google-button{width: 60px;}
.contact-title{
    color: #ed3237;
    font-size: 17px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0 0 20px 0;
}
.chi-nhanh-list .row{
    margin: 0 -10px;
}
.chi-nhanh-item{
    padding: 0 10px;
}
.chi-nhanh-title{
    font-size: 13px;
    font-weight: bold;
    text-transform: uppercase;
    color: #767676;
    margin: 0 0 15px 0;
    min-height: 24px;
    /* background: url(../images/icon-map.png) left top no-repeat; */
    padding: 5px 0 0 25px;
}
.chi-nhanh-item p{
    margin: 0;
    color: #5e5f60;
    line-height: 1.4;
}
.chi-nhanh-map{
    margin-top: 7px;
}
.chi-nhanh-map p{
    margin: 0;
}
.chi-nhanh-map iframe{
    width: 100%;
    max-height: 220px;
}
.contact-form{
    margin-top: 25px;
}
.tax-child{
    text-align: center;
}
.tax-child-item {
  display: inline-block;
  box-sizing: inherit;
}
.tax-child-img {
  width: 160px;
  height: 160px;
  position: relative;
}
.tax-child-img img {
  position: absolute;
  margin: auto;
  max-width: 80%;
  max-height: 80%;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
}
.tax-child-img:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}
.tax-child-item h2 {
  text-align: center;
  font-weight: normal;
  font-size: 12px;
  text-transform: uppercase;
  line-height: 18px;
  height: 18px;
  overflow: hidden;
  margin: 0;
}
.product-list{
    margin: 0 -15px;
}
.product-item{
    width: 19%;
    float: left;
    position: relative;
    margin-bottom: 20px;
    /* padding: 0 15px; */
    margin-left: 10px;
    margin-top: 10px;
    cursor: pointer;
}
.product-item:nth-child(5n+1){
    clear: both;
}
.product-item .product-img{
    width: 100%;
    height: 190px;
    background: #FFF;
    position: relative;
    border-bottom: 1px dotted #ababab;
}
.product-item .product-img a img {
    max-width: 90%;
    max-height: 90%;
    /* position: absolute; */
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.buy-now-label{
    position:absolute;
    background:#ff3400;
    color:#fff;
    opacity:.6;
    text-transform:uppercase;
    font-size:15px;
    font-weight:1000;
    padding:10px 10px 8px 10px;
    border-radius:3px;
    top:37%;
    left:29%;
    display:none
}

.product-item:hover{
    box-shadow:0 0 3px #bbb;
    text-decoration:none;
    color:#333
}

.product-item:focus{outline:0;text-decoration:none;color:#333}
.product-item:hover,.product-item:focus{text-decoration:none;outline:0;color:#333}
.product-img:hover .buy-now-label{display:block}
.buy-now-label{top:37%;left:29%}

.product-item .pro-label-hot {
    /* background: url(../images/icon-hot.png) no-repeat; */
    width: 59px;
    height: 24px;
    position: absolute;
    bottom: -1px;
    left: 0;
    z-index: 9999;
}
.product-item .pro-label-new {
    /* background: url(../images/icon-new.png) no-repeat; */
    width: 59px;
    height: 24px;
    position: absolute;
    bottom: -1px;
    left: 0;
    z-index: 9999;
}
.product-item .pro-label-sale {
    /* background: url(../images/icon-sale.png) no-repeat; */
    width: 59px;
    height: 24px;
    position: absolute;
    bottom: -1px;
    left: 0;
    z-index: 99;
}
.product-title{
    font-size: 15px;
    color: #ababab;
    margin: 15px 0 10px 0;
}
.product-title a{
    color: #337ab7;
    font-weight: bold
}
.product-title a:hover{
    color: #ed3237;
}
.product-price{
    margin: 0;
    color: #ed3237;
    font-size: 15px;
    font-weight: 500;
    position: relative;
}
.product-price .pro-label-gift{
    /* background: url(../images/icon-gift.png) no-repeat; */
    width: 79px;
    height: 21px;
    position: absolute;
    top: -1px;
    right: 0;
    z-index: 99;
}
.product-action{
    margin-top: 15px;
}
.product-action label{
    font-weight: normal;
    margin: 0;
    cursor: pointer;
    color: #2f2f2f;
}
.product-action input{
    float: left;
    margin: 3px 5px 0 0;
}
.btn-add-compare{
    margin: 2px 0 0 0;
}
.btn-add-cart{
    background: #9a9a9a;
    color: #fff;
    border-radius: 20px;
    padding: 3px 10px 2px 10px;
    font-size: 12px;
    transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
    -moz-transition: 0.5s ease;
    -ms-transition: 0.5s ease;
    -o-transition: 0.5s ease;
}
.btn-add-cart:hover{
    background: #ed3237;
    color: #fff;
}
.btn-add-cart .glyphicon{
    font-size: 13px;
    margin-right: 3px;
}

.product-list.line{
    margin: 0;
}
.product-item-line{
    padding: 15px 0;
    float: left;
    clear: both;
    border-bottom: solid 1px #EAEAEA;
    width: 100%;
    margin-bottom: 15px;
}
.product-item-line .product-img {
    width: 200px;
    height: 200px;
    position: relative;
    float: left;
    margin-right: 50px;
}
.product-item-line .product-img img {
    max-width: 170px;
    max-height: 170px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
.product-item-line .product-desc {
    float: left;
    width: 320px;
    margin-right: 20px;
}
.product-item-line .product-title{
    margin: 0;
}
.product-item-line .product-title a{
    color: #008546;
    font-weight: bold;
}
.product-item-line .product-title a:hover{
    color: #003f21;
}
.product-item-line .product-desc .product-price{
    font-weight: bold;
    margin: 9px 0;
}
.product-item-line .product-desc p{
    margin: 0;
}
.product-item-line .product-store {
    float: left;
    width: 100%;
    border-top: dashed 1px #ccc;
    margin-top: 10px;
    padding-top: 10px;
}
.product-item-line .product-info{
    width: 300px;
    float: left;
    margin-right: 20px;
}
.product-item-line .product-action{
    float: left;
}
.product-item-line .product-action .btn-add-cart{
    float: none;
}
.product-item-line .product-action .btn-add-compare{
    float: none;
    margin-top: 15px;
}

.filter-title{
    padding: 10px 0;
    margin-bottom: 0;
}
.filter-title .span{
   float: left;
   margin-top: 15px;
}
.sort-filter{
    float: right;
}
.sort-filter > ul{
    margin: 0;
    list-style: none;
}
.sort-filter > ul > li{
    float:left;
    position:relative;
    border-right:1px solid #dbdbdb;
}
.sort-filter > ul > li.icononly {
  border: none;
}
.sort-filter > ul > li:last-child{border-right:none;}
.sort-filter > ul > li > a{
    float:left;
    font-size:12px;
    font-weight:bold;
    color:#323232;
    display:block;
    padding: 15px 10px 12px 10px;
    text-decoration:none;
}
.sort-filter > ul > li > a span{color:#323232;padding-left:2px;}
.sort-filter > ul > li > ul{
    display:none;
    position:absolute;
    top:33px;
    left:0px;
    z-index: 10;
    background:#f6f6f6;
    border:1px solid #d9d9d9;
    border-top:none;
    list-style: none;
}
.sort-filter > ul > li:hover > ul{display:block;}
.sort-filter > ul > li > ul > li > a{
    white-space:nowrap;
    min-width:120px;
    font-size:11px;
    color:#474747;
    text-decoration:none;
    padding:0px 10px;
    line-height:30px;
    height:30px;
    display:block;
}
.sort-filter > ul > li > ul > li > a:hover{background:#FFF;}

.sort-box{
    float: right;
    padding-top: 14px;
}
.sort-select {
    position: relative;
    float: left;
    min-width: 140px;
    margin: 0 0 0 5px;
}
.sort-select > span {
    border: solid 1px #9a9a9a;
    display: block;
    height: 23px;
    background: white;
    padding: 0 10px;
    line-height: 23px;
    color: #222;
    font-family: Arial, sans-serif;
    font-size: 12px;
    padding-right: 25px;
}
.sort-select span.icon_dropdown {
    margin-left: 7px;
    width: 9px;
    height: 5px;
    background-position: -349px -44px;
    vertical-align: middle;
    display: inline-block;
    /* background: url(../images/drop-down-menu.png) no-repeat; */
    position: absolute;
    top: 9px;
    right: 10px;
}
.sort-select ul {
    margin: 0;
    list-style: none;
    background: white;
    border: solid 1px #9a9a9a;
    padding: 3px 8px;
    display: none;
    width: 100%;
    position: absolute;
    z-index: 99;
    line-height: 20px;
    top: 23px;
    left: 0;
    box-sizing: border-box;
    max-height: 200px;
    overflow-y: scroll;
}
.sort-select:hover ul {
    display: block;
}
.sort-select li{
    width: 100%;
}
.sort-select ul a {
    font-size: 10px;
    color: #2f2f2f;
}

.view_sp_grid_line{
    float:left;
    margin-left: 5px;
}
.iconview-line{
    width:24px;
    height:23px;
    display:block;
    float:left;
    /* background:url(../images/iconview.png) no-repeat 0px 0px; */
    margin:0px 3px 0px 0px;
    -webkit-transition:0.2s;
    transition:0.2s;
    cursor: pointer;
}
.iconview-line:hover{
    /* background:url(../images/iconview.png) no-repeat 0px -23px; */
    -webkit-transition:0.2s;
    transition:0.2s;
}
.iconview-line.active{
    /* background:url(../images/iconview.png) no-repeat 0px -23px; */
    -webkit-transition:0.2s;
    transition:0.2s;
}
.iconview-grid{
    width:24px;
    height:23px;
    display:block;
    float:left;
    /* background:url(../images/iconview.png) no-repeat -24px 0px; */
    margin:0px 3px 0px 0px;
    -webkit-transition:0.2s;
    transition:0.2s;
    cursor: pointer;
}
.iconview-grid:hover{
    -webkit-transition:0.2s;
    transition:0.2s;
    /* background:url(../images/iconview.png) no-repeat -24px -23px; */
}
.iconview-grid.active{
    /* background:url(../images/iconview.png) no-repeat -24px -23px; */
}

/* Tooltip */
.zing-tooltip {
    position: absolute;
    width: 230px;
    height: auto;
    float: left;
    z-index: 999999;
    border: solid 1px #c8c8c8;
    background: #fff;
    display: none;
}
.tooltip-header{
    background: #fff;
    padding: 10px;
}
.tooltip-title{
    margin: 0;
    font-weight: bold;
    font-size: 13px;
    color: #000;
}
.tooltip-price{
    margin: 8px 0 0 0;
}
.tooltip-body{
    background: #f2f2f2;
    padding: 10px;
}
.tooltip-body p{
    line-height: 1.3;
    margin: 0;
    color: #3a3a3a;
    font-size: 13px;
}
.tooltip-body p strong{
    color: #000;
}
.tooltip-footer{
    padding: 10px;
    background: #fff;
}
.tooltip-footer p:first-child{
    color: red;
    font-weight: bold;
    margin-bottom: 8px;
}
/* Tooltip */
.zing-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    display:none;
    background: rgba(0, 0, 0, 0.5);
}
.zing-popup-box{
    margin: 15px;
    background: #fff;
    position: relative;
}
.zing-popup-open{
    overflow: hidden;
}
.zing-popup-open .zing-popup{
    overflow-x: hidden;
    overflow-y: auto;
}
.zing-popup-title{
    margin: 0;
    padding: 10px 20px;
    font-size: 16px;
    border-bottom: 1px solid #ccc;
    font-weight: bold;
    color: #000;
}
.zing-popup-close{
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: -10px;
    right: -10px;
    text-align: center;
    line-height: 20px;
    background: red;
    color: #fff;
    font-size: 20px;
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid #fff;
}
.compare-item .product-inner{
    border-bottom: 1px solid #e1e1e1;
    text-align: left;
    padding-bottom: 10px;
}
.compare-item .product-inner:hover{
    border-color: #ff0000;
}
.compare-item .product-title{
    margin-bottom: 5px;
}
.compare-item .product-title a{
    color: #008546;
    font-weight: bold;
}
.compare-item .product-info p{
    margin: 0;
}
.compare-item .product-price{
    font-weight: bold;
}
.compare-action span {
  clear: both;
  text-align: center;
  margin: 5px auto;
  display: block;
  cursor: pointer;
  width: 145px;
}
.compare-item .product-desc{
    text-align: center;
    padding: 0 10px;
}
.compare-item .product-info{
    padding: 5px 10px;
}
.btn-quick-add-cart{
    font-size: 12px;
    color: #050505;
    padding: 6px 18px;
    background: -moz-linear-gradient(
        top,
        #eeeeee 0%,
        #dddddd 88%,
        #888888);
    background: -webkit-gradient(
        linear, left top, left bottom,
        from(#eeeeee),
        color-stop(0.88, #dddddd),
        to(#888888));
    border-radius: 2px;
    border: 0px solid #6d8000;
    box-shadow:
        0px 0px 0px rgba(000,000,000,0.5),
        inset 0px 0px 0px rgba(255,255,255,1);
    text-shadow:
        0px -1px 0px rgba(000,000,000,0.2),
        0px 1px 0px rgba(255,255,255,0.4);
}
.btn-remove-compare{
    font-size: 12px;
    color: #ffffff;
    padding: 6px 18px;
    background: -moz-linear-gradient(
        top,
        #eb0000 0%,
        #e30000 88%,
        #a30000);
    background: -webkit-gradient(
        linear, left top, left bottom,
        from(#eb0000),
        color-stop(0.88, #e30000),
        to(#a30000));
    border-radius: 2px;
    border: 0px solid #6d8000;
    box-shadow:
        0px 0px 0px rgba(000,000,000,0.5),
        inset 0px 0px 0px rgba(255,255,255,1);
    text-shadow:
        0px -1px 0px rgba(000,000,000,0.2),
        0px 1px 0px rgba(255,255,255,0.4);
}
.compare-noty {
  padding: 10px;
  text-align: center;
  color: #f00;
  font-size: 14px;
  border-bottom: 1px solid #ccc;
  margin: 0;
}
.product-left{
    width: 40%;
    float: left;
    border: 1px solid #e6e6e6;
    border-radius: 5px;
}
.product-left .img-main {
    width: 100%;
    height: 510px;
    width: 410px;
    position: relative;
}
.product-left .img-main img {
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 100%;
    max-height: 100%;
}
.product-thumnail-list{
    position: relative;
    border-top: 1px solid #dcdcdc;
}
.product-thumnail{
    padding: 10px 30px;
}
.product-thumnail .item{
    height: 80px;
    border: 1px solid #d6d6d6;
}
.product-thumnail img{
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    max-width: 90%;
    max-height: 90%;
    width: inherit !important;
}
.owl-prev-product{
    position: absolute;
    top: 50%;
    left: 10px;
    margin-top: -9px;
    z-index: 20;
}
.owl-next-product{
    position: absolute;
    top: 50%;
    right: 10px;
    margin-top: -9px;
    z-index: 20;
}
.product-right{
    text-align: left;
    width: 60%;
    float: left;
    padding-left: 100px;
  }
.single-product-title{
    font-size: 20px;
    margin: 8px 0 15px 0;
    color: #337ab7;
    text-transform: uppercase;
    font-weight: bold;
}
.product-right-info{
    padding-right: 50px;
    position: relative;
}
.single-product-price{
    font-size: 18px;
    font-weight: bold;
    color: #ff0000;
    float: left;
    margin-bottom: 5px;
}
.product-share{
    float: right;
}
.product-share div{
    float: left;
    margin-left: 10px;
}
.product-share .google-button{
    display: inline-block;
    width: 55px;
}
.product-right-content{
    padding: 10px 15px 10px 0;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    max-height: 500px;
    overflow-y: auto;
}
.product-right-content::-webkit-scrollbar
{
   width: 8px;
   height: 45px;
}
.product-right-content::-webkit-scrollbar-track
{
   -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
.product-right-content::-webkit-scrollbar-thumb
{
   background-color: #ddd;
}
.product-right-title{
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 8px;
}
.product-btn{
    margin-top: 15px;
}
.product-btn a{
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 10px;
    background: #ed3237;
    color: #fff;
    padding: 6px 30px;
    transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
    -moz-transition: 0.5s ease;
    -ms-transition: 0.5s ease;
    -o-transition: 0.5s ease;
}

.product-btn a:hover{
    background: #880000;
}

.product-btn span{
    cursor: pointer;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 10px;
    background: #ed3237;
    color: #fff;
    padding: 6px 20px;
    transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
    -moz-transition: 0.5s ease;
    -ms-transition: 0.5s ease;
    -o-transition: 0.5s ease;
}

.product-btn span:hover{
    background: #880000;
}

.product-btn .btn-buy{
    margin-right: 10px;
}
.mo-ta-san-pham{
    line-height: 2;
}
.qua-tang-box{
    width: 250px;
    position: absolute;
    top: 32px;
    right: 0;
    border: 1px solid #ed3237;
    padding: 10px;
}
.qua-tang-title{
    font-size: 17px;
    margin-bottom: 8px;
    font-weight: bold;
    color: #ed3237;
    text-transform: uppercase;
    /* background: url(../images/icon-qua-tang.png) no-repeat left top; */
    padding-left: 30px;
}
.single-product-box{
    margin: 30px 0;
}
/* Checkout */
.customer-title{
    margin: 0 0 10px 0;
    color: #292929;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    /* background: url(../images/icon-nhan-hang.png) no-repeat center left 210px; */
    padding: 7px 0 5px 25px;
}
.customer-form{
    padding: 20px 20px 12px 20px;
    border: 1px solid #dbdbdb;
}
.customer-form .form-group{
    margin-bottom: 13px;
}
.customer-form textarea.form-control{
    height: 80px !important;
}
.cart-submit{
    width: 125px;
    height: 29px;
    background: #d72523;
    color: #fff;
    border: none;
    margin: auto;
    display: block;
    text-transform: uppercase;
    transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
    -moz-transition: 0.5s ease;
    -ms-transition: 0.5s ease;
    -o-transition: 0.5s ease;
}
.cart-submit:hover{
    background: #a20200;
}
.gio-hang-title{
    color: #000;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    padding: 12px 10px 8px 10px;
    margin: 0;
    border-bottom: 1px dashed #d1d1d1;
}
.cart-box{
    background: #f5f5f5;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    color: #000;
}
.cart-item{
    height: 130px;
    position: relative;
    padding: 12px 100px 12px 15px;
    min-height: 104px;
    border-bottom: 1px dashed #d1d1d1;
}
.cart-img{
    width: 100px;
    height: 100px;
    border: 1px solid #e9e9e9;
    position: absolute;
    top: 12px;
    right: 40px;
}

.cart-title{
    cursor: pointer;
    /* margin-bottom: 20px;
    margin-left: -10px;*/
    margin-left: 10px;
    margin-top: 5px; 
    font-size: 15px;
    font-weight: bold;
    color: #337ab7;
    text-transform: uppercase;
    position: fixed;
}

.cart-title:hover{
    color: #ed3237;
}

.cart-qty{
    width: 50px;
    height: 27px;
    border-radius: 3px;
    border: 1px solid #cecece;
    text-align: center;
    margin-left: 8px;
}
.cart-total{
    color: #ff0000;
    font-size: 18px;
    margin: 0;
    font-weight: bold;
    text-align: right;
    padding: 6px 15px;
    border-bottom: 1px dashed #d1d1d1;
}
.cart-bottom{
    text-align: right;
    padding: 10px;
}
.btn-remove-cart{
    color: #131313;
    font-size: 14px;
    font-weight: bold;
    /* background: url(../images/bg-cart-delete.png) right top no-repeat; */
    padding: 5px 35px 6px 0;
    display: inline-block;
}
.btn-remove-cart:hover{
    color: red;
}
.cart-buy{
    color: #131313;
    font-size: 16px;
    font-weight: bold;
}
.cart-buy:hover{
    color: red;
}
.btn-delete-cart{
    position: absolute;
    top: 0;
    right: 0;
}

.btn-delete-cart img{
    width: 25px;
    height: 25x;
    margin-top: 5px;
    margin-right: 30px;
}
.cart-empty{
    padding: 10px;
}
.cart-empty p{
    margin: 0;
}
/* Checkout */
.customer-area{
    margin-top: 0;
}

/* Build pc */
.builder-price{
    color: #F00;
    font-weight: bold;
    line-height: 22px;
    float: right;
    width: 100%;
    text-align: right;
    font-size: 16px;
    display: block;
}
.btn-builder-finish{
    color: #fff !important;
    font-size: 12px;
    border: none;
    float: left;
    margin: 0;
    background: #7abc52;
}
.btn-builder-finish:hover{
    background: #017035;
}
.builder-sort-box{
    border-top: solid 1px #DBDBDB;
    border-bottom: solid 1px #DBDBDB;
    min-height: 39px;
}
.builder-sort-box .sort-box{
    margin: 0;
}
.builder-sort-box .loc1{
    float: left;
}
.builder-sort-box .loc2{
    float: left;
}
.builder-sort-box .loc1 > ul{
    float:right;
}
.builder-sort-box .loc1 > ul > li{
    float:left;
    position:relative;
    border-right:1px solid #dbdbdb;
}
.builder-sort-box .loc1 > ul > li.icononly {
    border: none;
}
.builder-sort-box .loc1 > ul > li:last-child{border-right:none;}
.builder-sort-box .loc1 > ul > li > a{
    float:left;
    font-size:12px;
    font-weight:bold;
    color:#575757;
    line-height:39px;
    display:block;
    padding:0px 10px;
    text-decoration:none;
}
.builder-sort-box .loc1 > ul > li > a span{color:#0574bb;padding-left:2px;}
.builder-sort-box .loc1 > ul > li > ul{
    display:none;
    position:absolute;
    top:39px;
    left:0px;
    z-index: 20;
    background:#f6f6f6;
    border:1px solid #d9d9d9;
    border-top:none;
}
.builder-sort-box .loc1 > ul > li:hover > ul{display:block;}
.builder-sort-box .loc1 > ul > li > ul > li > a{
    white-space:nowrap;
    min-width:120px;
    font-size:11px;
    color:#474747;
    text-decoration:none;
    padding:0px 10px;
    line-height:30px;
    height:30px;
    display:block;
}
.builder-sort-box .loc1 > ul > li > ul > li > a:hover{background:#FFF;}
.builder-sort-box .loc1 > ul > li > ul > li > a:before{content:" Â» ";padding-right:10px;}
.builder-sidebar{
    width: 19%;
    float: left;
    border-right: 1px solid #bcbcbc;
}
.builder-sort-box .loc2 .sort-select{
    margin-top: 8px;
}
.builder-sidebar .step {
    font-size: 12px;
    text-transform: uppercase;
    padding: 5px;
    width: 100%;
    line-height: 22px;
    float: left;
    font-weight: bold;
    border-bottom: 1px solid #eaeaea;
}
.builder-sidebar .step:last-child{
    border-bottom: none;
}
.builder-sidebar a{
    color: #000;
}
.builder-sidebar .instep {
    background-color: #f6f6f6;
}
.builder-sidebar .step_label {
    width: 130px;
    float: left;
}
.buildpc_remove_item {
    position: absolute;
    color: red;
    margin-left: -55px;
    z-index: 9;
    float: left;
    margin-top: 0;
    cursor: pointer;
    text-transform: none;
    text-transform: initial;
}
.step_thumb img {
    width: 60px;
    height: auto;
    float: right;
    max-height: 50px;
}
.builder-product{
    float: left;
    width: 62%;
    padding: 0 20px;
    border-bottom: solid 1px #ccc;
}
.builder-product-item{
    padding: 10px;
    border-bottom: solid 1px #DBDBDB;
    min-height: 160px;
    float: left;
    width: 100%;
}
.builder-product-img{
    width: 180px;
    height: 140px;
    position: relative;
    float: left;
    margin-right: 30px;
}
.builder-product-img a img{
    max-width: 90%;
    max-height: 90%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
.builder-product-desc{
    float: left;
    width: 300px;
}
.builder-product-desc h2 {
    font-size: 15px;
    margin: 15px 0 10px 0;
}
.builder-product-desc h2 a{
    color: #ed3237;
    font-weight: bold;
    text-transform: uppercase;
}
.builder-product-desc h2 a:hover{
    color: #970000;
}
.pro-price {
    color: red;
    font-weight: bold;
    margin: 10px 0px 8px 0 !important;
    font-size: 16px;
}
.builder-product-desc p{
    margin: 0;
    line-height: 20px;
}
.add-to-builder{
    float: right;
    margin: 30px 0 0 0;
}
.btn-add-builder{
    color: #fff;
    font-size: 14px;
    border: none;
    margin: 0;
    background: #9a9a9a;
    padding: 3px 20px;
    cursor: pointer;
    margin-right: 10px;
    border-radius: 20px;
    transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
    -moz-transition: 0.5s ease;
    -ms-transition: 0.5s ease;
    -o-transition: 0.5s ease;
}
.btn-add-builder:hover{
    background: #cd0005;
}
.builder-checkout-wapper {
    max-width: 960px;
    border: solid 1px #ccc;
    margin: auto;
}
.builder-checkout-control {
    max-width: 960px;
    margin: 20px auto;
}
.builder-checkout-control .builder-price {
    text-align: center;
    margin: 0px;
    padding: 0px;
    margin-bottom: 20px;
}
.builder-checkout-control .builder-price p{
    margin: 0;
}
.builder-action a{
    color: #fff !important;
    font-size: 14px;
    border: none;
    float: left;
    margin: 0;
    background: #00a859;
    padding: 5px 20px;
    cursor: pointer;
    text-transform: inherit;
    border-radius: 4px;
}
.builder-action a:hover{
    background: #017035;
}
.builder-action .buidertocart{
    margin-left: 250px;
    background: #d93434;
    background-image: -webkit-linear-gradient(top, #d93434, #b82b2b);
    background-image: linear-gradient(to bottom, #d93434, #b82b2b);
}
.builder-action .buidertocart:hover{
    background: #960000;
}
.builder-action  .buidertoprint{
    float: right;
}
.builder-filter{
    width: 19%;
    float: right;
    border-left: 1px solid #bcbcbc;
}
/* Build pc */
.builder-filter .widget-title{
    font-size: 14px;
    color: #ef2025;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0;
    padding: 10px 12px;
}
.widget-filter ul{
    margin: 0;
    list-style: none;
}
.widget-filter > ul > li{
    padding: 7px 12px;
    border-top: 1px solid #eaeaea;
    position: relative;
    cursor: pointer;
}
.widget-filter > ul > li > a{
    display: block;
    color: #000;
    font-size: 13px;
    font-weight: bold;
}
.widget-filter > ul > li > a span{
    display: none;
}
.widget-filter > ul > li::before{
    display: inline-block;
    width: 7px;
    height: 1px;
    background: #000;
    content: "";
    right: 10px;
    top: 18px;
    position: absolute;
}
.widget-filter > ul > li::after{
    display: inline-block;
    width: 7px;
    height: 1px;
    background: #000;
    content: "";
    right: 10px;
    top: 18px;
    position: absolute;
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
}
.widget-filter > ul > li.active::after{
    display: none;
}
.widget-filter > ul > li ul{
    margin-top: 10px;
    display: none;
}
.widget-filter > ul > li ul li{
    line-height: 24px;
}
.widget-filter > ul > li ul li a{
    font-size: 12px;
    color: #787878;
    display: block;
}
.widget-filter > ul > li ul li a:hover{
    color: #000;
}
.builder-product .pagination{
    text-align: center;
    margin-top: 15px;
}
.builder-product .pagination a, .builder-product .pagination span{
    margin-left: 0;
    margin-right: 10px;
}
.builder-box{
    display: flex;
    flex-flow: row column;
}
.register-form{
    margin: 0;
}
.btn-register{
    padding: 6px 20px;
    border: none;
    margin: auto;
    display: block;
    background: #00a859;
    border-radius: 4px;
    color: #fff;
    text-transform: uppercase;
    font-size: 14px;
    transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
    -moz-transition: 0.5s ease;
    -ms-transition: 0.5s ease;
    -o-transition: 0.5s ease;
}
.btn-register:hover{
    background: #017035;
}
.home-image{
	margin-top: 10px;
}
.home-image img{
    max-width: 100%;
    width: auto;
    height: auto;
}
.home-image-left{
    padding: 0 5px 0 0;
}
.home-image-right{
    padding: 0 0 0 5px;
    float: right;
}
.home-image-right img{
    float: right;
}
/* zing tab */
.zing-tab{
    margin: 30px 0 30px 0;
}
.tab-header{
    width: 100%;
    border-bottom: 1px solid #ccc;
    float: left;
}
.tab-header ul{
    margin: 0;
    list-style: none;
}
.tab-header li{
    float: left;
    position: relative;
}
.tab-header li p{
    color: #707171;
    font-size: 15px;
    padding: 8px 15px;
    display: block;
    text-transform: uppercase;
}
.tab-header li p.active{
    color: #ed3237;
    position: relative;
}
.tab-header li a.active::after{
    content: "";
    width: 100%;
    height: 3px;
    position: absolute;
    bottom: -2px;
    left: 0;
    background: #ed3237;
}
.tab:not(:first-child){
    display: none;
}
.tab-content{
    text-align: justify;
    font-size: 15px;
    margin-left: 15px;
    margin-right: 50px;

    padding: 20px 0 0 0;
}
.tab-content table{
    width: 100%;
}
.tab-content td{
    padding: 2px 5px;
}
/* zing tab */
.builder-finish .step{
    background: #ed3237;
    color: #fff;
}
.filter_query {
  clear: both;
  float: left;
  width: 100%;
  min-height: 40px;
  border: solid 1px #DBDBDB;
  line-height: 40px;
  background-color: #fff;
  margin-bottom: 10px;
  padding: 0px 20px;
  text-align: left;
}
.filter_query_wapper {
  float: left;
}
.filter_query .filter_items > span{
    margin-left: 10px;
  padding-right: 10px;
  line-height: 40px;
  float: left;
}
.filter_items {
  float: left;
}
.filter_fcom {
  float: left;
  font-weight: bold;
  color: #0059ac;
  margin-right: 10px;
}
.filter_items span b{
  color: #000;
}
.filter_items span {
  color: #0059ac;
/* background:url(../images/vachfilter.jpg) no-repeat center right; */
}
.filter_items span:last-child{
background:none;
}
.filter_items a {
  margin-left: 10px;
  display: inline-block;
  float: right;
  line-height: 37px;
}
.xdch_preview {
    background-color: #F8F8F8;
    border: 1px solid #C0C0C0;
    border-radius: 3px;
    box-shadow: 0 0 2px #C0C0C0;
    margin-top: 40px;
    position: relative;
    width: 100%;
    float: left;
    padding: 20px;
    background: #f8f8f8;
}
.thong-tin {
    background: none repeat scroll 0 0 #ed3237;
    border: 1px solid #C0C0C0;
    color: #FFFFFF;
    font-size: 18px;
    margin: auto;
    padding: 5px 10px;
    position: absolute;
    top: -20px;
}
.order-detail {
    margin-top: 10px;
    border-right: 1px solid #ed3237;
    border-bottom: 1px solid #ed3237;
	/* background:url(../images/logoopacity.png) no-repeat center center #fff; */
}
.order-detail .name, .order-detail .price, .order-detail .product_type {
    border-left: 1px solid #ed3237;
    border-top: 1px solid #ed3237;
    height: 29px;
    padding: 10px;
    vertical-align: middle !important;
}
.price-total, .name.total {
	font-size: 14px;
    font-weight: bold;
    text-align: center;
    color: #000;
}
.price-total {
    color: #ed3237;
}
.build_select_num {
    width: 100%;
    clear: both;
    float: left;
}
.build_select_num select {
    width: 100%;
    height: 34px;
    border-radius: 5px;
    background: #f0f0f0;
    font-size: 14px;
}
td.product_type {
    text-transform: uppercase;
    font-weight: bold;
    color: #000;
    font-size: 12px;
}
.order-detail .product_label {
    border-left: 1px solid #ed3237;
    border-top: 1px solid #ed3237;
    height: 29px;
    padding: 10px;
    vertical-align: middle !important;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    color: #000;
}
.builder-wrap.content-box {
    float: left;
    width: 100%;
}
.build_pc_bottom {
    float: left;
    width: 100%;
}
.xdch_preview:nth-child(1) {
    width: 950px;
    margin: auto;
    float: none;
    margin-top: 36px;
}
.xdch_preview:nth-child(2) {
    width: 750px;
    margin: auto;
    float: none;
    margin-top: 36px;
}
span.buildpc_remove_all_item {
    cursor: pointer;
    color: red;
}
